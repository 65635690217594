.modal {
  display: none;
  z-index: 3;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: $lightgray;
  overflow: scroll;
  @extend %font-size-md;

  .modal-body {
    @extend .wrapper;
    padding-top: $spacing-md;
  }

  .file-container {
    display: none;
    width: 100%;
    border: 1px #BCBCBC solid;

    figure {
      position: relative;
      width: 100%;
    }

    .element--img {
      display: block;
      width: 100%;
      pointer-events: none;
    }

    video {
      max-width: 100%;
      height: auto;
      display: block;
    }

    .focal-point {
      display: flex;
      position: absolute;
      top: calc(50% - 16px);
      left: calc(50% - 16px);
      user-select: none;
      cursor: move;
      background-color: #0051cc;
      border-radius: 100%;
      height: 32px;
      width: 32px;
      transition: transform 0.15s ease-out, background 0.15s ease-out;

      &.active {
        // transform: scale(1.2);
        background-color: #0065FF;
      }

    }
  }

  .panel-heading {
    display: block;
    margin-top: $spacing-sm;
  }

  .panel-action {
    margin-bottom: $spacing-lg;
    margin-top: 0;
  }

  .panel.panel-edit.panel-edit--single {
    max-width: 640px;
    width: 90% !important;

    .edit__header,
    .panel-heading {
      display: block;
    }

    .panel-heading {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }


}

.modal-dialog {
  .modal-content {
    width: 100%;
    height: 100vh;
    display: flex;
  }

  .modal-header {
    display: block;
    margin: auto;
    max-width: 100%;
    width: 350px;
  }

  .modal-title {
    @extend .ts-title;
    color: $base-color;
    margin-bottom: $spacing-md;
  }

  .btn {
    // margin-bottom: $spacing-xxs;
  }
}
